import { DialogService }                        from 'aurelia-dialog';
import { bindable, inject }                     from 'aurelia-framework';
import { I18N }                                 from 'aurelia-i18n';
import { BaseListViewModel }                    from 'base-list-view-model';
import { CreateBMTestApplicationLocationModal } from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/create';
import { EditBMTestApplicationLocationModal }   from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/edit';
import { FilterFormSchema }                     from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/filter-form-schema';
import { BMTestApplicationLocationsRepository } from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/services/repository';
import { ViewBMTestApplicationLocationModal }   from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/view';
import { AppContainer }                         from 'resources/services/app-container';

@inject(AppContainer, BMTestApplicationLocationsRepository, FilterFormSchema, I18N, DialogService)
export class ListBMTestApplicationLocations extends BaseListViewModel {

    listingId        = 'bituminous-mixtures.mixtures.lab.application-locations.listing';
    permissionPrefix = 'bituminous-mixtures.mixtures.lab.application-locations';

    @bindable headerTitle = null;
    @bindable repository;
    @bindable datatable;
    @bindable bmLabTest;

    readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param i18N
     * @param dialogService
     */
    constructor(appContainer, repository, filterFormSchema, i18N, dialogService) {
        super(appContainer);

        this.i18N             = i18N;
        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            `${this.permissionPrefix}.manage`,
            `${this.permissionPrefix}.view`,
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.bmLabTest = params.model;
        this.readonly  = params.readonly;

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);

        this.lot_intervention_id = this.bmLabTest.lot_intervention_id;

        this.defineDatatable();
        this.schema = this.datatable;
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.repository.search({ ...criteria, bm_test_id: this.bmLabTest.id }),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:            {
                action:  (row) => this.openModal(row, ViewBMTestApplicationLocationModal),
                visible: (row) => this.appContainer.authenticatedUser.can([`${this.permissionPrefix}.manage`, `${this.permissionPrefix}.view`]),
            },
            edit:            {
                action:  (row) => this.openModal(row, EditBMTestApplicationLocationModal),
                visible: (row) => !this.readonly && this.appContainer.authenticatedUser.can([`${this.permissionPrefix}.manage`, `${this.permissionPrefix}.edit`]),
            },
            destroy:         !this.readonly && this.appContainer.authenticatedUser.can([`${this.permissionPrefix}.manage`, `${this.permissionPrefix}.delete`]),
            destroySelected: !this.readonly,
            showPagination:  false,
            actions:         [],
            options:         [],
            buttons:         [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly && this.appContainer.authenticatedUser.can([`${this.permissionPrefix}.manage`, `${this.permissionPrefix}.create`]),
                    action:           () => this.openModal(this, CreateBMTestApplicationLocationModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'lot_construction_designation',
                    name:  'lot_construction_translations.designation',
                    title: 'form.field.location',
                },
                {
                    data:       'formatted_pk',
                    name:       'formatted_pk',
                    title:      'form.field.pk-to-pk',
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:  'track_name',
                    name:  'track_translations.name',
                    title: 'form.field.track',
                },
                {
                    data:  'observations',
                    name:  'observations',
                    title: 'form.field.observations',
                },
            ],
        };
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}

import { inject }                     from 'aurelia-framework';
import { TracksRepository }           from 'modules/aggregates/lab/services/tracks-repository';
import { ConstructionSchema }         from 'modules/aggregates/lab/tab-schemas/construction-schema';
import { BMTestApplicationLocation }  from 'modules/bituminous-mixtures/models/bm-application-location';
import { OEDatasRepository }          from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';

@inject(LotInterventionsRepository, OEDatasRepository, TracksRepository)
export class FormSchema extends ConstructionSchema {

    schemaSlug = 'application_location';

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    constructor(lotInterventionsRepository, oeDatasRepository, tracksRepository) {
        super(lotInterventionsRepository, oeDatasRepository, tracksRepository);
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {BMTestApplicationLocation}
     */
    model() {
        let model = new BMTestApplicationLocation();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    async schema(viewModel, readonly = false) {

        // Set ConstructionSchema fields
        await super.schema(viewModel, readonly);

        this.observations = {
            type:       'textarea',
            key:        'observations',
            idPrefix:   this.schemaSlug,
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        // Add pk selector to applied_pk field-group
        this.applied_pk.fields = [
            this.oe_data_id, // pk_id
            ...this.applied_pk.fields,
        ];

        // Make pk selector not required
        this.oe_data_id.required = false;

        // Change sizes
        this.oe_data_id.size           = 3; // pk selector
        this.applied_pk.fields[1].size = 2; // pks_s
        this.applied_pk.fields[2].size = '60p'; // divider
        this.applied_pk.fields[3].size = 2; // pks_f
        this.applied_pk.fields[4].size = '60p'; // divider
        this.applied_pk.fields[5].size = 2; // pkf_s
        this.applied_pk.fields[6].size = '60p'; // divider
        this.applied_pk.fields[7].size = 2; // pkf_f
        this.applied_pk.size           = 12;
        this.track_id.size             = 12;

        const fields = [
            [this.lot_construction_id, this.branch_id, this.edge_id],
            [this.applied_pk],
            [this.track_id],
            [this.observations]
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [
                ...fields,
                [this.created_by, this.created_at],
            ];
        }

        return fields;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {

        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}

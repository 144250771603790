import { DialogController }                     from 'aurelia-dialog';
import { bindable, inject }                     from 'aurelia-framework';
import { PLATFORM }                             from 'aurelia-pal';
import { BaseFormViewModel }                    from 'base-form-view-model';
import { FormSchema }                           from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/form-schema';
import { BMTestApplicationLocationsRepository } from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/services/repository';
import { AppContainer }                         from 'resources/services/app-container';

@inject(AppContainer, DialogController, BMTestApplicationLocationsRepository, FormSchema)
export class CreateBMTestApplicationLocationModal extends BaseFormViewModel {

    headerTitle      = 'form.title.create-new-record';
    permissionPrefix = 'bituminous-mixtures.mixtures.lab';
    formId           = `${this.permissionPrefix}.creation-form`;

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    bmLabTestId;

    @bindable settings = {
        title:   'form.title.create-new-record',
        size:    'modal-lg',
        buttons: false,
    };

    constructor(appContainer, dialogController, repository, formSchema) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
        this.formSchema       = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/bm-tests/application-locations/form.html');
    }

    canActivate() {
        return super.can([
            `${this.permissionPrefix}.manage`,
            `${this.permissionPrefix}.create`,
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.schema       = await this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.assignBMTestInformation(params.bmLabTest);

    }

    /**
     * Assign necessary BM Test related data, to model and initialModel
     * @param bmTest
     */
    assignBMTestInformation(bmTest) {
        const info = {
            bm_test_id:          bmTest.id,
            sector_id:           bmTest.sector_id,
            lot_intervention_id: bmTest.lot_intervention_id,
        }

        this.model.assign(info);
        this.initialModel.assign(info);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response);

                if (response.status === true) {
                    this.initialModel.assign(this.model);
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

}

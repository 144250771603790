import { DialogController }                  from 'aurelia-dialog';
import { bindable, inject }                  from 'aurelia-framework';
import { PLATFORM }                          from 'aurelia-pal';
import { BaseListViewModel }                 from 'base-list-view-model';
import { ApprovedWorkingFormulasRepository } from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/services/repository';
import { AppContainer }                      from 'resources/services/app-container';

@inject(AppContainer, ApprovedWorkingFormulasRepository, DialogController)
export class ApprovedWorkingFormulaAdditionalInformationsModal extends BaseListViewModel {

    listingId = 'bituminous-mixtures-approved-working-formulas-additional-information-modal-listing';

    @bindable headerTitle    = 'listing.additional-informations.additional-informations';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    @bindable alert = {};

    @bindable settings = {
        title:   'form.title.additional-informations',
        size:    'modal-lg',
        buttons: false,
    };

    fullData = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.mixture = params;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.searchAdditionalInformations(criteria),
                destroy:         null,
                destroySelected: false,
            },
            edit:            null,
            destroy:         null,
            destroySelected: false,
            options:         [],
            buttons:         [],
            selectable:      false,
            actions:         [],
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'number',
                    name:  'additional_informations.number',
                    title: 'form.field.number',
                },
                {
                    data:  'description',
                    name:  'additional_information_translations.description',
                    title: 'form.field.description',
                },
                {
                    data:       'file',
                    name:       'file',
                    title:      'form.field.file',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/management/additional-information/additional-informations/custom-listing-cells/file/index'),
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    searchAdditionalInformations(criteria) {
        return this.repository.searchAdditionalInformations(this.mixture, criteria);
    }

    /**
     * Cancels the dialog
     *
     * @return {*}
     */
    cancel() {
        return this.dialogController.cancel();
    }
}

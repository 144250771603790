import { DialogController }                     from 'aurelia-dialog';
import { bindable, inject }                     from 'aurelia-framework';
import { PLATFORM }                             from 'aurelia-pal';
import { BaseFormViewModel }                    from 'base-form-view-model';
import { FormSchema }                           from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/form-schema';
import { BMTestApplicationLocationsRepository } from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/services/repository';
import { CompositionDataRepository }            from 'modules/management/bituminous-mixtures/composition-data/services/repository';
import { AppContainer }                         from 'resources/services/app-container';

@inject(AppContainer, DialogController, BMTestApplicationLocationsRepository, FormSchema, CompositionDataRepository)
export class ViewBMTestApplicationLocationModal extends BaseFormViewModel {

    headerTitle      = 'form.title.view-record';
    permissionPrefix = 'bituminous-mixtures.mixtures.lab';
    formId           = `${this.permissionPrefix}.view-form`;

    @bindable alert = {};
    model           = {};
    schema          = {};
    readonly        = true;

    @bindable settings = {
        title:   'form.title.view-record',
        size:    'modal-lg',
        buttons: false,
    };

    constructor(appContainer, dialogController, repository, formSchema) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
        this.formSchema       = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/bm-tests/application-locations/form.html');
    }

    canActivate() {
        return super.can([
            `${this.permissionPrefix}.manage`,
            `${this.permissionPrefix}.create`,
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        this.schema       = await this.formSchema.schema(this, true);
        this.globalSchema = this.formSchema.globalSchema(this, true);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}

import { inject }                            from 'aurelia-framework';
import { PLATFORM }                          from 'aurelia-pal';
import { BaseFormViewModel }                 from 'base-form-view-model';
import { FormSchema }                        from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/form-schema';
import { ApprovedWorkingFormulasRepository } from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/services/repository';
import { LotInterventionsRepository }        from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { AppContainer }                      from 'resources/services/app-container';

@inject(AppContainer, ApprovedWorkingFormulasRepository, FormSchema, LotInterventionsRepository)
export class CreateManufacturingPlantTransposition extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'bituminous-mixtures.mixtures.approved-working-formulas.creation-form';

    alert   = {};
    warning = {};
    model   = {};
    schema  = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param lotInterventionsRepository
     */
    constructor(appContainer, repository, formSchema, lotInterventionsRepository) {
        super(appContainer);

        this.repository                 = repository;
        this.formSchema                 = formSchema;
        this.lotInterventionsRepository = lotInterventionsRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/approved-working-formulas/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('bituminous-mixtures.mixtures.approved-working-formulas.index') && super.can([
            'bituminous-mixtures.mixtures.approved-working-formulas.manage',
            'bituminous-mixtures.mixtures.approved-working-formulas.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.schema                      = this.formSchema.schema(this, false, true);
        this.additionalInformationSchema = this.formSchema.additionalInformationSchema(this);
        this.lotInfoSchema               = this.formSchema.lotInfoSchema(this);
        this.globalSchema                = this.formSchema.globalSchema(this);

        this.fillInfo = this.infoAlertMessage(this.appContainer.i18n.tr('form.message.before-sample-selection'));
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, 'bituminous-mixtures.mixtures.approved-working-formulas.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }
}

import { DialogService }              from 'aurelia-dialog';
import { EventAggregator }            from 'aurelia-event-aggregator';
import { inject }                     from 'aurelia-framework';
import { I18N }                       from 'aurelia-i18n';
import { TracksRepository }           from 'modules/aggregates/lab/services/tracks-repository';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { BaseFilterFormSchema }       from 'resources/classes/base-filter-form-schema';
import { SessionStorage }             from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, DialogService, TracksRepository, LotInterventionsRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        //
    };

    constructor(storage, eventAggregator, i18n, dialogService, tracksRepository, lotInterventionsRepository) {
        super(storage, eventAggregator, i18n, dialogService);

        this.tracksRepository           = tracksRepository;
        this.lotInterventionsRepository = lotInterventionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {

        this.lot_construction_designation = {
            type:  'text',
            key:   'lot_construction_designation',
            label: 'form.field.local',
            //remoteSource: () => this.lotInterventionsRepository.allConstructions(viewModel.lot_intervention_id, LotConstructionType.OE),
            size:     6,
            required: false,
        };

        this.track_name = {
            type:  'text',
            key:   'track_name',
            label: 'form.field.track',
            //remoteSource: () => this.tracksRepository.all(),
            size:     6,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.lot_construction_designation, this.track_name],
            [this.buttons],
        ];
    }
}

import { DialogController }                     from 'aurelia-dialog';
import { bindable, inject }                     from 'aurelia-framework';
import { PLATFORM }                             from 'aurelia-pal';
import { BaseFormViewModel }                    from 'base-form-view-model';
import { FormSchema }                           from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/form-schema';
import { BMTestApplicationLocationsRepository } from 'modules/bituminous-mixtures/mixtures/bm-tests/application-locations/services/repository';
import { AppContainer }                         from 'resources/services/app-container';

@inject(AppContainer, DialogController, BMTestApplicationLocationsRepository, FormSchema)
export class EditBMTestApplicationLocationModal extends BaseFormViewModel {

    headerTitle      = 'form.title.create-new-record';
    permissionPrefix = 'bituminous-mixtures.mixtures.lab';
    formId           = `${this.permissionPrefix}.edition-form`;

    @bindable alert = {};
    model           = {};
    schema          = {};

    @bindable settings = {
        title:   'form.title.edit-record',
        size:    'modal-lg',
        buttons: false,
    };

    constructor(appContainer, dialogController, repository, formSchema) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
        this.formSchema       = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/bm-tests/application-locations/form.html');
    }

    canActivate() {
        return super.can([
            `${this.permissionPrefix}.manage`,
            `${this.permissionPrefix}.create`,
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        this.schema       = await this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response);
                this.initialModel.assign(this.model);

                if (response.status === true) {
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
